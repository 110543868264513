<template>
  <section>
    <v-card-title>
      Settings
    </v-card-title>

    <div class="settings-pricing" v-if="!!pricingSettings">
      <v-col class="d-md-flex flex-column">
        <v-form ref="form" lazy-validation class="settings-pricing__form">
          <div class="settings__title">{{ pricingSettings.title }}</div>
          <v-text-field
            v-model="pricingSettings.value.minOrderMiles"
            hide-details="auto"
            :rules="requiredRules"
            label="Minimal Order (miles)"
            class="mt-4"
            required
            type="number"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="pricingSettings.value.minPriceDriver"
            hide-details="auto"
            :rules="requiredRules"
            label="Minimal Price Driver"
            class="mt-4"
            required
            type="number"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="pricingSettings.value.minPriceCustomer"
            hide-details="auto"
            :rules="requiredRules"
            label="Minimal Price Customer"
            class="mt-4"
            required
            type="number"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="pricingSettings.value.priceDriverMile"
            hide-details="auto"
            :rules="requiredRules"
            label="Price Driver (per mile)"
            class="mt-4"
            required
            type="number"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="pricingSettings.value.priceCustomerMile"
            hide-details="auto"
            :rules="requiredRules"
            label="Price Customer (per mile)"
            class="mt-4"
            required
            type="number"
            outlined
          ></v-text-field>
          <div class="my-2 d-flex justify-center">
            <v-btn color="primary" @click.native="saveSetting(pricingSettings)"
              >Save</v-btn
            >
          </div>
        </v-form>
      </v-col>
    </div>
    <v-snackbar v-model="snackbar">
      {{ notification }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      pricingSettings: null,
      requiredRules: [(v) => !!v || "Field is required"],

      notification: "",
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters(["user", "strapi"]),
  },
  async mounted() {
    var results = await this.$apollo.query({
      query: require("@/graphql/SettingsAll.gql"),
    });
    for (let i = 0; i < results.data.settings.length; i++) {
      switch (results.data.settings[i].name) {
        case "pricing":
          this.pricingSettings = results.data.settings[i];
          break;
      }
    }
  },
  methods: {
    saveSetting(settings) {
      this.strapi.updateEntry("settings", parseInt(settings.id), settings);
      this.notification = "Successfull";
      this.snackbar = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings {
  &__title {
  }
}

.settings-pricing {
  &__form {
    max-width: 300px;
  }
}
</style>
